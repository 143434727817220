import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Canada",
  "subtitle": "-",
  "category": "North America"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "vancouver--north-vancouver"
    }}>{`Vancouver / North Vancouver`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1iwG7wsP8RVRi21x7"
        }}>{`Honey Doughnuts`}</a>{` - Might be difficult to find parking nearby`}</li>
    </ul>
    <h4 {...{
      "id": "dim-sum"
    }}>{`Dim Sum`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/sSpt7AFFXJqyAYRW9"
        }}>{`Pink Pearl`}</a>{` - Classic`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1zpokKgE5JERafMW8"
        }}>{`Sun Sui Wah`}</a>{` - Fancier`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jtHdb2yHDQRnS7Xt9"
        }}>{`Capilano Suspension Bridge Park`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HsHEzY9oBVX6RxNd9"
        }}>{`Quarry Rock`}</a>{` - Easy hike with rewarding view`}</li>
    </ul>
    <h2 {...{
      "id": "richmond"
    }}>{`Richmond`}</h2>
    <p>{`Literally just here for food`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/FEEu9WjJTjsebWZ89"
        }}>{`HK BBQ Master`}</a>{` - 🔥`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1WUUb2Q4qcJgf1hv7"
        }}>{`Richmond Public Market`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/4ChsmCG3615XpQXc6"
            }}>{`Eggette House`}</a>{` - I always stop for an order of Milk tea, Hong Kong styled Lemon tea, and Bubble waffles!`}</li>
          <li parentName="ul">{`Old school Chinese vendors; so many options`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QXF9Set2vgFXFAJo9"
        }}>{`Shanghai River`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/t1mtGD1Coc5NiY4A9"
        }}>{`The Fish Man`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zqibx7Uk5jHLKBnL6"
        }}>{`Costco`}</a>{` - Chicken wings, Poutine, and Canadian products`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zABijnwfbiQjJwKm8"
        }}>{`T&T Supermarket`}</a>{` - I always stop at the Lansdowne location`}</li>
    </ul>
    <h2 {...{
      "id": "langley"
    }}>{`Langley`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VFmLzwov4dvrZfwh9"
        }}>{`Canadian Museum of Flight`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      